















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'StepHistory',
})
export default class StepHistory extends Vue {
  @Prop({ default: '-', required: true }) private title!: string
  @Prop({ default: '-', required: true }) private desc!: string
  @Prop({ default: true }) private sameColorDots!: boolean
  @Prop({ required: true }) private index!: number | string

  setDot(): string {
    if (this.sameColorDots) {
      return 'step-item-primary'
    }
    return this.index === 0 ? 'step-item-current' : 'step-item'
  }
}
