import { Form, History, LevelRule } from './interfaces'
import {
  DetailLoyaltyProgramMembership,
  EnumStatusLoyaltyMembership,
} from '@/domain/entities/LoyaltyProgramMembership'
import { Utils } from '@/app/infrastructures/misc'
import { FormLevelInterface } from '@/app/ui/controllers/LoyaltyProgramMembershipController'
import { UpdateLoyaltyProgramMembershipRequest } from '@/data/payload/api/LoyaltyProgramMembershipRequest'
import dayjs from 'dayjs'
interface LoyaltyMembershipState {
  form: Form
  histories: Array<History>
  setDataDetail: (val: DetailLoyaltyProgramMembership) => Form
  setHistories: (val: DetailLoyaltyProgramMembership) => History[]
  formatDate: (dateString: string) => string

  setBadgeTypeProgramStatus: (status: EnumStatusLoyaltyMembership) => string
  generatePayloadSave: (data: Form) => UpdateLoyaltyProgramMembershipRequest
}

const useActionLoyaltyMembership = (): LoyaltyMembershipState => {
  const form: Form = {
    backwardMonthPeriod: '',
    date1: {
      id: 0,
      val: '',
    },
    date2: {
      id: 0,
      val: '',
    },
    date3: {
      id: 0,
      val: '',
    },
    date4: {
      id: 0,
      val: '',
    },
    endDate: '',
    endTime: '',
    id: 0,
    isNonCA: false,
    isWithoutShipmentNonCA: false,
    levelRule: [
      ...new Array(5).fill('').map(
        (item, index): LevelRule => {
          return {
            id: index,
            name: '',
            minAmount: NaN,
          }
        }
      ),
    ],
    packageStatuses: [],
    shipmentIDs: [],
    startDate: '',
    startTime: '',
    status: '-',
    sttPrefix: [],
  }

  const histories: Array<History> = []

  const setDataDetail = (val: DetailLoyaltyProgramMembership): Form => {
    form.id = val.info?.id
    form.status = val.info?.status || '-'
    form.sttPrefix = val.info?.prefixSTTShipments || []
    form.shipmentIDs = val.info?.prefixShipmentIDs || []
    form.packageStatuses = val.info?.packageStatuses || []
    form.backwardMonthPeriod = val?.info?.backwardMonthPeriod
    form.isWithoutShipmentNonCA = val?.info?.isIncludeSTTNonCustomerApp || false

    // set data of schedules trigger
    if (val.dateSchedules && val.dateSchedules?.length > 0) {
      val.dateSchedules.forEach((dateSchedule, idx) => {
        const idxKey = `date${idx + 1}`
        if (dateSchedule.month > 0 && dateSchedule.date > 0) {
          const onDate = (num: number): string => {
            return num <= 9 ? `0${num}` : String(num)
          }

          const dateNow = new Date()
          let year = dateNow.getFullYear()
          if (dateSchedule.month < dateNow.getMonth()) {
            year = dateNow.getFullYear() + 1
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          form[idxKey] = {
            id: dateSchedule.id,
            val: `${year}-${onDate(dateSchedule.month)}-${onDate(
              dateSchedule.date
            )}`,
          }
        }
      })
    }


    form.startDate = Utils.formatDate(val.info?.startDate || '', '')
    form.startTime = Utils.formatDate(val.info?.startDate || '', 'HH:mm:ss')
    form.endDate = Utils.formatDate(val.info?.endDate || '', '')
    form.endTime = Utils.formatDate(val.info?.endDate || '', 'HH:mm:ss')

    // set levels
    if (val.levels && val.levels.length > 0) {
      const levels = []
      for (const data of val.levels) {
        levels.push({
          id: data.id,
          name: data.name,
          minAmount: data.minimalAmountTransaction,
        })
      }
      form.levelRule = levels
    }

    return form
  }

  const setHistories = (
    val: DetailLoyaltyProgramMembership | undefined
  ): History[] => {
    if (val && val.histories && val.histories.length > 0) {
      for (const data of val.histories.reverse()) {
        const changeNameStatus = data.status.toLowerCase().startsWith('start')
          ? 'start'
          : data.status
        histories.push({
          title: Utils.toCapitalize(changeNameStatus),
          date: formatDate(data.createdAt),
        })
      }
    }
    return histories
  }

  const formatDate = (dateString: string): string => {
    const date = Utils.formatDate(dateString, 'DD MMMM YYYY')
    const clock = Utils.formatDate(dateString, 'HH:mm')
    return `${date} • ${clock}`
  }

  const setBadgeTypeProgramStatus = (
    status: EnumStatusLoyaltyMembership
  ): string => {
    if (status === EnumStatusLoyaltyMembership.ACTIVE) {
      return 'success'
    }

    if (
      [
        EnumStatusLoyaltyMembership.PAUSED,
        EnumStatusLoyaltyMembership.SCHEDULED,
      ].includes(status)
    ) {
      return status.toLowerCase()
    }

    if (
      [
        EnumStatusLoyaltyMembership.STOPPED,
        EnumStatusLoyaltyMembership.EXPIRED,
      ].includes(status)
    ) {
      return 'error'
    }

    return 'expire'
  }

  const generatePayloadSave = (
    data: Form
  ): UpdateLoyaltyProgramMembershipRequest => {
    const dateSchedules = [
      {
        id: data.date1.id,
        date: data.date1.val.split('-')[2],
        month: data.date1.val.split('-')[1],
      },
      {
        id: data.date2.id,
        date: data.date2.val.split('-')[2],
        month: data.date2.val.split('-')[1],
      },
      {
        id: data.date3.id,
        date: data.date3.val.split('-')[2],
        month: data.date3.val.split('-')[1],
      },
      {
        id: data.date4.id,
        date: data.date4.val.split('-')[2],
        month: data.date4.val.split('-')[1],
      },
    ].filter(item => {
      return item.date
    })

    const levels = data.levelRule.map(item => {
      return {
        name: String(item?.name),
        minAmount: Number(item?.minAmount),
        minNumber: null,
        id: item.id,
      }
    })

    const formatSchedule = 'YYYY-MM-DD HH:mm:ss'
    const formatOnDate = 'YYYY-MM-DD'

    const generateDate = (
      dateVal: string | undefined,
      timeVal: string | undefined
    ): string => {
      const d = Utils.formatDate(dateVal || '', formatOnDate)
      return dayjs(`${d} ${timeVal}`, formatSchedule).format()
    }

    return new UpdateLoyaltyProgramMembershipRequest(
      {
        start_date: generateDate(data.startDate, data.startTime),
        end_date: generateDate(data.endDate, data.endTime),
        prefix_stt_shipments: data.sttPrefix,
        prefix_shipment_ids: data.shipmentIDs,
        is_include_stt_non_ca: data.isWithoutShipmentNonCA,
        package_statuses: data.packageStatuses,
        backward_month_period: data.backwardMonthPeriod || 0,
      },
      dateSchedules.map(item => {
        return {
          id: item.id,
          date: Number(item.date),
          month: Number(item.month),
        }
      }),
      levels.map((item: FormLevelInterface) => {
        return {
          id: item.id,
          level_name: item.name,
          min_amount_transaction: item.minAmount,
          min_number_transaction: item.minNumber,
        }
      })
    )
  }

  return {
    form,
    histories,
    setDataDetail,
    setHistories,
    formatDate,
    setBadgeTypeProgramStatus,
    generatePayloadSave,
  }
}

export default useActionLoyaltyMembership
