











































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MultiLevel from '@/app/ui/components/MultiLevel/index.vue'
import FormLevel from '../components/FormLevel/index.vue'
import { IObjectMultiLevel } from '@/app/ui/components/MultiLevel/type'
import MembershipTextInput from '../components/MembershipTextInput/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import MembershipDatePicker from '../components/MembershipDatePicker/index.vue'
import ButtonBase from '@/app/ui/components/Button/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import ModalConfirmation from '../components/ModalConfirmation/index.vue'
import StepHistory from '@/app/ui/components/StepHistory/index.vue'
import controller from '@/app/ui/controllers/LoyaltyProgramMembershipController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  DetailLoyaltyProgramMembership,
  EnumStatusLoyaltyMembership
} from '@/domain/entities/LoyaltyProgramMembership'
import useActionLoyaltyMembership from '@/app/ui/views/LoyaltyProgram/Membership/useCase'
import { Form, History } from '@/app/ui/views/LoyaltyProgram/Membership/useCase/interfaces'

@Component({
  name: 'DetailLoyaltyProgramMembershipPage',
  components: {
    Badge,
    EditIcon,
    MultiLevel,
    FormLevel,
    MembershipTextInput,
    LoadingOverlay,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    MembershipDatePicker,
    ButtonBase,
    ModalConfirmation,
    StepHistory,
  },
})
export default class DetailLoyaltyProgramMembershipPage extends Vue {
  controller = controller
  useAction = useActionLoyaltyMembership()
  ObjectMultiLevel: IObjectMultiLevel[] = [
    {
      prefix: 'Level 1 (Lowest)',
      isOpen: true,
      isError: false,
      errorMessage: '',
    },
    {
      prefix: 'Level 2',
      isOpen: true,
      errorMessage: '',
    },
    {
      prefix: 'Level 3',
      isOpen: true,
      errorMessage: '',
    },
    {
      prefix: 'Level 4',
      isOpen: true,
      errorMessage: '',
    },
    {
      prefix: 'Level 5 (Highest)',
      isOpen: true,
      errorMessage: '',
    },
  ]

  disableButton = false
  form: Form = this.useAction.form

  histories: History[] = this.useAction.histories
  modalConfirmation = {
    visible: false,
    title: '',
    subtitle: '',
    buttonOK: '',
  }

  created(): void {
    controller.getOne(+this.$route.params.id)
  }

  @Watch('controller.detailLoyaltyMembership')
  onChangeDataDetail(val: DetailLoyaltyProgramMembership): void {
    this.form = this.useAction.setDataDetail(val)
    this.histories = this.useAction.setHistories(val)
  }

  getStatus(statusProgram: string): EnumStatusLoyaltyMembership {
    return <EnumStatusLoyaltyMembership>statusProgram.toUpperCase()
  }

  showButtonEdit(statusProgram: string): boolean {
    return [
      EnumStatusLoyaltyMembership.ACTIVE,
      EnumStatusLoyaltyMembership.PAUSED,
      EnumStatusLoyaltyMembership.SCHEDULED
    ].includes(this.getStatus(statusProgram))
  }
  showButtonStop(statusProgram: string): boolean {
    return [
      EnumStatusLoyaltyMembership.ACTIVE,
      EnumStatusLoyaltyMembership.PAUSED,
      EnumStatusLoyaltyMembership.SCHEDULED
    ].includes(this.getStatus(statusProgram))
  }
  showButtonPaused(statusProgram: string): boolean {
    return [EnumStatusLoyaltyMembership.ACTIVE].includes(this.getStatus(statusProgram))
  }

  showButtonStart(statusProgram: string): boolean {
    return [EnumStatusLoyaltyMembership.PAUSED].includes(this.getStatus(statusProgram))
  }

  // for start and pause feature
  // private showModalSubmit(): void {
  //   this.modalConfirmation = {
  //     visible: true,
  //     title: 'Want to Start Program?',
  //     subtitle: 'Make sure the data are correct before you start program',
  //     buttonOK: 'Start',
  //   }
  // }

  setBadgeTypeProgramStatus(): string {
    return this.useAction.setBadgeTypeProgramStatus(this.getStatus(<string>this.form.status))
  }

  // for start and pause feature
  // private closeModal(): void {
  //   this.modalConfirmation = {
  //     visible: false,
  //     title: '',
  //     subtitle: '',
  //     buttonOK: '',
  //   }
  // }

  // for start and pause feature
  // private onSubmit(): void {
  //   if (this.modalConfirmation.buttonOK === 'Start') {
  //     alert('submit')
  //   } else {
  //     alert('cancel')
  //   }
  // }
}
